import React from 'react';
import './sub-header.scss';
import { NavLink } from 'react-router-dom';

const Header = () => {
  return (
    <nav className="subheader">
      <div className="container">
        <ul className="subheader-nav">
          <li>
            <NavLink to="/dashboard/profile/profile-info" activeClassName="active">
              Profile
            </NavLink>
          </li>
          <li>
            <NavLink to="/dashboard/inbox" activeClassName="active">
              Inbox{' '}
            </NavLink>
          </li>
          <li>
            <a className="">My Reservations</a>
          </li>
          <li>
            <a className="">My Wishlists</a>
          </li>
          <li>
            <NavLink to="/dashboard/acount-settings/view-account" activeClassName="active">
              Account Settings
            </NavLink>
          </li>
          <li>
            <a className="">Refer a friend</a>
          </li>
        </ul>
      </div>
    </nav>
  );
};
export default Header;
