/**
 *
 * DesktopHeader
 *
 */
/* eslint-disable no-unused-vars */
import { Fragment, useState, useEffect, useContext } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import ClassNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

const DesktopHeader = ({ headerTransparent, isLoggedIn }) => {
  return (
    <nav
      className={ClassNames(
        ClassNames(
          { 'header-container': true },
          { '--isTransparent': headerTransparent }
        )
      )}
      dir="ltr"
    >
      <div className="__column">
        <NavLink className="__logo" to="/"></NavLink>
      </div>
      <div className="__column">
        <div className="__desktopNavItems">
          {!isLoggedIn ? (
            <NavLink to="/login" className="__navItem --isHighlighted">
              Log in
            </NavLink>
          ) : (
            ''
          )}

          <NavLink
            to="/landing/become-host"
            className="__navItem"
            href="/landings/become-a-host"
          >
            Home
          </NavLink>
          <NavLink
            to="/landing/become-host"
            className="__navItem"
            href="/landings/become-a-host"
          >
            Book a Ride
          </NavLink>
          <NavLink
            to="/landing/become-host"
            className="__navItem"
            href="/landings/become-a-host"
          >
            About Us
          </NavLink>
        </div>
      </div>
    </nav>
  );
};

DesktopHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default DesktopHeader;
