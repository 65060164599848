import React, { createContext, useReducer } from 'react';

const DEFAULT = {
  stack: 'Programming',
  technology: '',
  course: '',
  programmingId: '',
};

const ConfigReducer = (state, action) => {
  switch (action.type) {
    case 'set-bradcrum': {
      return { ...state, ...action.payload };
    }
    case 'set-program': {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

export const ContainerCtx = createContext({});

export default function ConfigCtxProvider({ children }) {
  const [state, dispatch] = useReducer(ConfigReducer, DEFAULT);
  return <ContainerCtx.Provider value={{ state, dispatch }}>{children}</ContainerCtx.Provider>;
}
