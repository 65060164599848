import React, { Component } from 'react';
import Slider from 'react-slick';
import './bike-slider.scss';

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <div className="slick-container container">
        <Slider {...settings}>
          <div>
            <div className="row container">
              <div className="col-8 m8 s12 bike-img">
                <picture>
                  <source
                    type="image/webp"
                    src="https://static.gobikes.co.in/images/carousel-3.webp"
                  />
                  <img
                    className="lazy"
                    src="https://static.gobikes.co.in/images/carousel-3.png"
                    alt="carousel-3"
                  />
                </picture>
              </div>
              <div className="col-4 m4 s12 bike-info">
                <h4>KTM Duke 390</h4>
                <p>₹ 1499/Day</p>
                <button className="rent-now search-btn">
                  Rent Now
                </button>
              </div>
            </div>
          </div>
          <div>
            <div className="row container">
              <div className="col-8 m8 s12 bike-img">
                <picture>
                  <source
                    type="image/webp"
                    src="https://static.gobikes.co.in/images/carousel-3.webp"
                  />
                  <img
                    className="lazy"
                    src="https://static.gobikes.co.in/images/carousel-3.png"
                    alt="carousel-3"
                  />
                </picture>
              </div>
              <div className="col-4 m4 s12 bike-info">
                <h4>KTM Duke 390</h4>
                <p>₹ 1499/Day</p>
                <button className="rent-now btn">
                  Rent Now
                </button>
              </div>
            </div>
          </div>
          <div>
            <div className="row container">
              <div className="col-8 m8 s12 bike-img">
                <picture>
                  <source
                    type="image/webp"
                    src="https://static.gobikes.co.in/images/carousel-3.webp"
                  />
                  <img
                    className="lazy"
                    src="https://static.gobikes.co.in/images/carousel-3.png"
                    alt="carousel-3"
                  />
                </picture>
              </div>
              <div className="col-4 m4 s12 bike-info">
                <h4>KTM Duke 390</h4>
                <p>₹ 1499/Day</p>
                <button className="rent-now btn">
                  Rent Now
                </button>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}
