/**
 *
 * HeroContainer
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import './hero-container.scss';
import TrendingContainer from '../TrendingContainer';

const HeroContainer = ({ children }) => {
  return (
    <div className="features-trending container">
      <div className="hero-container">
        <div className="hero-header right">
          <div className="underline"></div>
          <h2>Why Gobikes</h2>
        </div>
        <div className="hero-header left">
          <div className="underline"></div>
          <h2>Features Provided</h2>
        </div>
        <div className="row">
          <div className="col m6 refer-earn">
            <div className="header">Register and book your ride now!</div>
            <div className="button-div">
              <button className="waves-effect waves-light btn search-btn">
                Register Now
              </button>
              <button className="waves-effect waves-light btn search-btn">
                Book a Ride
              </button>
            </div>
            </div>
            <div className="col m6 features">
              <div className="features">
                <div className="row">
                  <div className="col m6 s12">
                    <div className="row">
                      <div className="col s3 m3 feature-icon">
                        <picture>
                          <source
                            type="image/webp"
                            src="https://static.gobikes.co.in/images/feature-1.webp"
                          />
                          <img
                            alt="feature-1"
                            className="lazy"
                            src="https://static.gobikes.co.in/images/feature-1.png"
                          />
                        </picture>
                      </div>
                      <div className="col s9 m9 feature-description">
                        <h4>Lowest Price Guarantee</h4>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col s3 m3 feature-icon">
                        <picture>
                          <source
                            type="image/webp"
                            src="https://static.gobikes.co.in/images/feature-2.webp"
                          />
                          <img
                            alt="feature-2"
                            className="lazy"
                            src="https://static.gobikes.co.in/images/feature-2.png"
                          />
                        </picture>
                      </div>
                      <div className="col s9 m9 feature-description">
                        <h4>Doorstep Delivery</h4>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col s3 m3 feature-icon">
                        <picture>
                          <source
                            type="image/webp"
                            src="https://static.gobikes.co.in/images/feature-3.webp"
                          />
                          <img
                            alt="feature-3"
                            className="lazy"
                            src="https://static.gobikes.co.in/images/feature-3.png"
                          />
                        </picture>
                      </div>
                      <div className="col s9 m9 feature-description">
                        <h4>High Quality Bikes</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col m6 s12">
                    <div className="row">
                      <div className="col s3 m3 feature-icon">
                        <picture>
                          <source
                            type="image/webp"
                            src="https://static.gobikes.co.in/images/feature-4.webp"
                          />
                          <img
                            alt="feature-4"
                            className="lazy"
                            src="https://static.gobikes.co.in/images/feature-4.png"
                          />
                        </picture>
                      </div>
                      <div className="col s9 m9 feature-description">
                        <h4>24/7 Operational</h4>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col s3 m3 feature-icon">
                        <picture>
                          <source
                            type="image/webp"
                            src="https://static.gobikes.co.in/images/feature-5.webp"
                          />
                          <img
                            alt="feature-5"
                            className="lazy"
                            src="https://static.gobikes.co.in/images/feature-5.png"
                          />
                        </picture>
                      </div>
                      <div className="col s9 m9 feature-description">
                        <h4>No Hard Documentation</h4>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col s3 m3 feature-icon">
                        <picture>
                          <source
                            type="image/webp"
                            src="https://static.gobikes.co.in/images/feature-6.webp"
                          />
                          <img
                            alt="feature-6"
                            className="lazy"
                            src="https://static.gobikes.co.in/images/feature-6.png"
                          />
                        </picture>
                      </div>
                      <div className="col s9 m9 feature-description">
                        <h4>Roadside Assistance</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <TrendingContainer />
      </div>
  );
};

HeroContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default HeroContainer;
