import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';

const MobileMenu = ({ isLoggedIn, toggleMenu }) => {
  /* const logout = () => {
    document.body.dispatchEvent(new CustomEvent('logout'));
  }; */
  return (
    <Fragment>
      <div className="side-menu-left">
        <div className="main-menu">
          <ul className="main-menu__list">
            <li className={'main-menu__item'}>
              <NavLink
                activeClassName="active"
                className="main-menu__item"
                to="/landing/become-host"
              >
                Find Scooty
              </NavLink>
            </li>
            <li className={'main-menu__item'}>
              <NavLink
                activeClassName="active"
                className="main-menu__item"
                to="/landing/become-host"
              >
                Become Scooty
              </NavLink>
            </li>
            <li className={'main-menu__item'}>
              <NavLink activeClassName="active" className="main-menu__item" to="/settings">
                Settings
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div className="overlay" onClick={toggleMenu} />
    </Fragment>
  );
};

export default MobileMenu;

