/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { ApolloContext } from 'react-apollo';
import { TranslationCtx } from '../HOCs/transaltionCtx';
import ForgotPassword from '../components/forgot-password';
import forgotPasswordQuery from '../graphql/query/forgotPassword';
import UserInfoHook from '../hooks/userinfo';
import { ConfigCtx } from '../HOCs/configCtx';

export default function ForgotPasswordPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const { client } = useContext(ApolloContext);
  const [isForgotPasswordComplete, setIsForgotPasswordComplete] = useState(false);

  function onResponse({ data }) {
    const { success } = data;
    if (!success) {
      setError('Something went wrong!');
      setIsLoading(false);
      return;
    }
    setMessage('Password reset mail has been sent successfully');
    setTimeout(() => {
      setIsForgotPasswordComplete(true);
    }, 2000);
  }

  function onError({ graphQLErrors, networkError } = { networkError: 'Something went wrong!' }) {
    if (networkError) {
      setError('Something went wrong!');
      setIsLoading(false);
      return;
    }
    const [{ extensions }] = graphQLErrors;
    const response = extensions.response && extensions.response.body;
    setError(response.message);
    setIsLoading(false);
  }

  function tryResetPassword({ email }) {
    setIsLoading(true);
    client
      .query({
        fetchPolicy: 'no-cache',
        query: forgotPasswordQuery,
        variables: {
          email,
        },
      })
      .then(({ data }) => onResponse(data))
      .catch(onError);
  }

  if (isForgotPasswordComplete) {
    return <Redirect to="/login" />;
  }

  return (
    <div className="page__container">
      <ForgotPassword
        onSubmit={tryResetPassword}
        message={message}
        isLoading={isLoading}
        error={error}
      />
    </div>
  );
}
