/* eslint-disable no-console */
import React from 'react';
import { useContext, useEffect, useState } from 'react';
import { ApolloContext } from 'react-apollo';
import USER_PASSWORD_UPDATE from '../../../graphql/query/userPasswordUpdate';

import ChangePasswordComponent from '../../../components/account-setting/change-password';
// eslint-disable-next-line no-unused-vars
export default function ProfileInfo({ user }) {
  const { client } = useContext(ApolloContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const onError = ({ graphQLErrors, networkError } = { networkError: 'Something went wrong!' }) => {
    if (networkError) {
      setError('Something went wrong!');
      setIsLoading(false);
      return;
    }
    const [firstError] = graphQLErrors;
    setError(firstError.message);
    setIsLoading(false);
  };
  const onResponse = () => {
    setIsLoading(false);

    // task successful
  };
  const onSave = (data) => {
    client
      .query({
        fetchPolicy: 'no-cache',
        mutation: USER_PASSWORD_UPDATE,
        variables: {
          password: data.password,
        },
      })
      .then(({ data }) => onResponse(data))
      .catch(onError);
  };
  useEffect(() => {}, []);
  return <ChangePasswordComponent isLoading={isLoading} error={error} onSave={onSave} />;
}
