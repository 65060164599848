import React from 'react';
import BookmarkBtn from '../../atoms/BookmarkBtn/index';
import FavBtn from '../../atoms/FavBtn/index';
import './palette.scss';
export default function Palette({
  nid,
  favourites,
  status,
  pointsInfo,
  totalComments,
  bookmarkStatus,
}) {
  return (
    <div className="image__likes-points">
      {
        <span className="image__points-value">
          <span className="image__value">{pointsInfo || '0'}</span>
        </span>
      }
      <FavBtn nid={nid} status={status} count={favourites || 0} />
      {
        <span className="image__comment-value">
          <span className="image__value">{totalComments || '0'}</span>
        </span>
      }
      <BookmarkBtn nid={nid} status={bookmarkStatus || false} />
    </div>
  );
}
