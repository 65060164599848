import { gql } from 'apollo-boost';

const registerQuery = gql`
  mutation register($email: String!, $password: String!, $username: String!) {
    registerData: register(email: $email, password: $password, username: $username) {
      success
      description
      message
    }
  }
`;

export default registerQuery;
