import React from 'react';
// eslint-disable-next-line no-unused-vars
import { Query } from 'react-apollo';
// eslint-disable-next-line no-unused-vars
import { NavLink, Switch, Route } from 'react-router-dom';
import ProfileInfo from './grand-child/ProfileInfo';
const ProfilePage = () => {
  // eslint-disable-next-line no-console
  return (
    <>
      <div className="default-layout">
        <div className="__content">
          <div className="sidebar-layout container">
            <div className="verical-nav">
              <ul>
                <li>
                  <NavLink activeClassName="active" to="/dashboard/profile/profile-info">
                    Profile Information
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active" to="/dashboard/profile/become-host">
                    Become Scooty{' '}
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active" to="/dashboard/profile/update-profile">
                    Update Profile{' '}
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active" to="/dashboard/profile/review">
                    Reviews{' '}
                  </NavLink>
                </li>
              </ul>
            </div>
            <Route path="/dashboard/profile/profile-info" component={ProfileInfo} />
            <Route path="/dashboard/profile/review" component={ProfileInfo} />
            <Route path="/dashboard/profile/update-profile" component={ProfileInfo} />
            <Route path="/dashboard/profile/become-host" component={ProfileInfo} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfilePage;
