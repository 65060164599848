/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';
import './search-box.scss';
import { withRouter } from 'react-router-dom';
const Technology = () => {
  return (
    <React.Fragment>
      <div className="search-box">
        <div className="search-box__wrapper">
          <div className="search-box__wrapper--container">
            <div className="homePage-SearchForm container">
              <div className="__inputGroup __searchInputGroup" style={{ flex: '1' }}>
                <div className="__searchInput">
                  <div className="__openSearchInputContainer">
                    <input type="text" className="__input" name="q" />
                  </div>
                </div>
              </div>
              <div className="__inputGroup __submitButtonGroup" style={{ flex: 'none' }}>
                <button className=" __btn __submitButton" disabled="">
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Technology);
