/* eslint-disable no-console */
import React, { Fragment } from 'react';
import ClassNames from 'classnames';

export default function FavBtn({ status, favCount }) {
  return (
    <Fragment>
      <span
        className={ClassNames('image__likes-value', { flagged: status })}
        tabIndex={-1}
        role="button"
      >
        <span className="image__value">{favCount || '0'}</span>
      </span>
    </Fragment>
  );
}
