import React from 'react';
import './primary-section.scss';

export default function TileComponent() {
  return (
    <div className="section-block section-bg">
      <div className="section-inner-container">
        <div className="section-title">+ Cities Covered all over India</div>
        <ul>
          <li>
            <div type="dining" className="tile-card">
              <div className="section-1">
                <h3 className="card-title" color="inherit" type="large">
                  Cities Covered all over India
                </h3>
                <p className="card-desc">Search Scooty nearby and Contact for babysitting Job </p>
              </div>
              <div className="section-2">
                <button className="Button-sc-1070a3v-0 OfferCard__HoverButton-sc-88wf9g-6 gErLsU">
                  View More
                </button>
              </div>
            </div>
          </li>
          <li>
            <div type="dining" className="tile-card">
              <div className="section-1">
                <h3 className="card-title" color="inherit" type="large">
                  + All category Vehicle
                </h3>
                <p className="card-desc">
                  Become Scooty and explore opportunity nearby for different helper jobs
                </p>
              </div>
              <div className="section-2">
                <button className="Button-sc-1070a3v-0 OfferCard__HoverButton-sc-88wf9g-6 gErLsU">
                  View More
                </button>
              </div>
            </div>
          </li>
          <li>
            <div type="dining" className="tile-card">
              <div className="section-1">
                <h3 className="card-title" color="inherit" type="large">
                  + Vendors to provide Vehicles
                </h3>
                <p className="card-desc">
                  Get Helper for your family by looking at reviews and comments
                </p>
              </div>
              <div className="section-2">
                <button className="Button-sc-1070a3v-0 OfferCard__HoverButton-sc-88wf9g-6 gErLsU">
                  View More
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
