import React from 'react';
import ReactDOM from 'react-dom';
import Routing from './routing';
import './assets/scss/index.scss';
import ConfigCtxProvider from './HOCs/configCtx';
import MenuCtxProvider from './HOCs/menuContext';

import Apollo from './HOCs/apollo';
import TransalationProvider from './HOCs/transaltionCtx';
import ContainerCtx from './HOCs/containerCtx';

function Root() {
  return (
    <ContainerCtx>
      <MenuCtxProvider>
        <ConfigCtxProvider>
          <TransalationProvider>
            <Apollo>
              <Routing />
            </Apollo>
          </TransalationProvider>
        </ConfigCtxProvider>
      </MenuCtxProvider>
    </ContainerCtx>
  );
}

ReactDOM.render(<Root />, document.body);
