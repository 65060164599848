/**
 *
 * HeroContainer
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import './tending-container.scss';
import BikeSlider from '../BikeSlider/BikeSlider';

const HeroContainer = ({ children }) => {
  return (
    <div className="trending-container">
      <div className="home-header left">
        <div className="underline"></div>
        <h2>Trending Bikes</h2>
      </div>
      <BikeSlider />
    </div>
  );
};

HeroContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default HeroContainer;
