/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Query, ApolloContext } from 'react-apollo';
import LOGGEDIN_USER_DATA from '../../../graphql/query/loggedinUserDataQuery';
import USER_UPDATE from '../../../graphql/query/userUpdate';

import ProfileInfoComponent from '../../../components/profile/profile-info';
// eslint-disable-next-line no-unused-vars
function ProfileInfo({ user }) {
  const { client } = useContext(ApolloContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const onError = ({ graphQLErrors, networkError } = { networkError: 'Something went wrong!' }) => {
    if (networkError) {
      setError('Something went wrong!');
      setIsLoading(false);
      return;
    }
    const [firstError] = graphQLErrors;
    setError(firstError.message);
    setIsLoading(false);
  };
  const onResponse = () => {
    setIsLoading(false);

    // task successful
  };
  const onSave = (data) => {
    console.log(data);
    client
      .query({
        fetchPolicy: 'no-cache',
        query: USER_UPDATE,
        variables: {},
      })
      .then(({ data }) => onResponse(data))
      .catch(onError);
  };
  useEffect(() => {}, []);
  return <ProfileInfoComponent isLoading={isLoading} error={error} onSave={onSave} user={user} />;
}

export default function ProfileInfoPage({ isLoggedIn }) {
  return (
    <Query query={LOGGEDIN_USER_DATA} fetchPolicy="network-only">
      {({ data, isLoading, error }) => {
        return (
          <ProfileInfo
            isLoggedIn={isLoggedIn}
            user={data && data.user ? data.user : {}}
            error={error}
          />
        );
      }}
    </Query>
  );
}
