/**
 *
 * MobileHeader
 *
 */
import { NavLink } from 'react-router-dom';
import ClassNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import ProfileIcon from '../../../assets/images/profile.svg';
import MobileMenu from '../MobileMenu';
import NotificationBar from '../NotificationBar';

const MobileHeader = ({
  isLoggedIn,
  toggleMenu,
  navLinkClass,
  openSideNav,
  headerTransparent,
}) => {
  return (
    <nav className={navLinkClass} dir="ltr">
      <div className="nav-bar">
        <button
          type="button"
          className="hamburger"
          onClick={() => openSideNav()}
        />
      </div>
      <div className="__column">
        <NavLink className="__logo" to="/"></NavLink>
      </div>
      <MobileMenu isLoggedIn={isLoggedIn} toggleMenu={toggleMenu} />
      <div className="__column">
        <div
          className={ClassNames(
            ClassNames(
              { __desktopNavItems: true },
              { '--isTransparent': headerTransparent }
            )
          )}
        >
          <NotificationBar headerTransparent={headerTransparent} />

          <NavLink to="/bookmarks" className="__navItem">
            <img
              className="__navItem__img"
              src={ProfileIcon}
              alt="profile-icon"
            />
          </NavLink>

          {!isLoggedIn ? (
            <NavLink to="/login" className="__navItem">
              Log in
            </NavLink>
          ) : (
            ''
          )}
        </div>
      </div>
    </nav>
  );
};

MobileHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default MobileHeader;
