/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import { gql } from 'apollo-boost';
import { Query, ApolloContext } from 'react-apollo';
import { BrowserRouter as Router, Switch, withRouter, Route, Redirect } from 'react-router-dom';
import HomePage from './pages/home';
import Loading from './helper/loader';
import Header from './components/templates/Header/Header';
import Container from './HOCs/containerCtx';
import { PrivateRoute, PublicRoute } from './helper/routes';
import LoginPage from './pages/login';
import ForgotPassword from './pages/forgot-password';
import UserSignup from './pages/signup';
import DashboardPage from './pages/dashboard';
import { ConfigCtx } from './HOCs/configCtx';
import useUserInfo from './hooks/userinfo';
import BecomeHost from './pages/become-host';
import PageNotFound from './components/404/page-not-found';
function authRedirect(isLoggedIn) {
  return isLoggedIn ? <Redirect to="/home/programming" /> : <Redirect to="/login" />;
}

const RouterChangeCallBack = withRouter(({ callback, history, location }) => {
  const status = !!localStorage.getItem('auth-token');
  let isTransparent = !(
    location.pathname.includes('landing') || location.pathname.includes('dashboard')
  );
  const { client } = useContext(ApolloContext);
  const logout = () => {
    client.cache.reset();
    localStorage.clear();
    callback(false);
    history.push('/login');
  };
  document.body.classList.remove('open');

  useEffect(() => {
    document.body.addEventListener('logout', logout);
    return () => document.body.removeEventListener('logout', logout);
  }, []);
  callback(status, isTransparent);
  return '';
});

const Routing = ({ loggedIn }) => {
  function callback(status, isTransparent) {
    setIsLoggedIn(status);
  }
  const [isLoggedIn, setIsLoggedIn] = useState(loggedIn);
  return (
    <Container>
      <Router>
        <Header isLoggedIn={isLoggedIn} />
        <Switch>
          <div className="body-container">
            <Route exact path="/" component={HomePage} />
            <Route exact isLoggedIn={isLoggedIn} path="/home" component={HomePage} />
            <PublicRoute exact isLoggedIn={isLoggedIn} path="/login" component={LoginPage} />
            <PublicRoute exact isLoggedIn={isLoggedIn} path="/register" component={UserSignup} />
            <PublicRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/forgetpassword"
              component={ForgotPassword}
            />
            <PublicRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/resetpassword/:token"
              component={HomePage}
            />
            <PublicRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/resetpassword/:token"
              component={HomePage}
            />
            <PrivateRoute
              exact
              isLoggedIn={isLoggedIn}
              path="/landing/become-host"
              component={BecomeHost}
            />
            <PublicRoute isLoggedIn={isLoggedIn} path="/dashboard" component={DashboardPage} />
          </div>
        </Switch>
        <RouterChangeCallBack callback={callback} />
      </Router>
    </Container>
  );
};
const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client(always: true)
  }
`;

export default function PageWithQuery() {
  return (
    <Query query={IS_LOGGED_IN}>
      {({ data: { isLoggedIn }, loading }) => {
        return !loading ? <Routing loggedIn={isLoggedIn} /> : <Loading />;
      }}
    </Query>
  );
}
