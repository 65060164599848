import React from 'react';
import '../components/home/home.scss';
import SearchBox from '../components/home/search-box';
import { withRouter } from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import Card from '../components/home-tiles/primary-section';
import GridTile from '../components/home/scootytiles';
import HeroContainer from '../components/templates/HeroContainer/HeroContainer';

// eslint-disable-next-line no-unused-vars
const Home = ({ }) => {
  const cssClass = `bg-container _1`;
  // eslint-disable-next-line no-console
  return (
    <>
      <div className="heroSection-container homePageSection ">
        <div className={cssClass}></div>
        <div dir="auto" className="heroSection__content">
          <h1 className="heading HeroSection__TitleHeading">
            Trace, Book, Ride Scooty
          </h1>
          <h2 className="subHeading">
            Scooty Rental Providers all over India | Get your ride
          </h2>
        </div>
        <SearchBox />
      </div>
      <HeroContainer />
      <Card />
      <GridTile />
    </>
  );
};

const HomePage = withRouter(Home);
export default HomePage;
