import React from 'react';
import './host-addon.scss';

const Course = () => {
  return (
    <div className="section-addon-host">
      <div className="container section-inner-container">
        <div className="__container">
          <div className="addon-card">
            <div height="100%" className="addon-bg"></div>
            <div className="addon-card__column">
              <img src="https://static.eatwith.com/58e4559f4eda4aa0eb19a58b25ddd30c.svg" />
              <h3 className="addon-card-title">Looking for a Scooty</h3>
              <p className="addon-card-subtitle">
                Search and find suitable Candidate for your family needs
              </p>
              <div className="addon-btn-sec">
                <button className="addon-btn">Search</button>
              </div>
            </div>
          </div>
          <div className="addon-card">
            <div height="100%" className="addon-bg"></div>
            <div className="addon-card__column">
              <img src="https://static.eatwith.com/58e4559f4eda4aa0eb19a58b25ddd30c.svg" />
              <h3 className="addon-card-title">Want to become a Scooty</h3>
              <p className="addon-card-subtitle">
                Join as a Member and explore all around in your region
              </p>
              <div className="addon-btn-sec">
                <button className="addon-btn">Join as a Scooty</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Course;
