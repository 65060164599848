import React from 'react';
// eslint-disable-next-line no-unused-vars
import { Query } from 'react-apollo';
// eslint-disable-next-line no-unused-vars
import { NavLink, Switch, Route } from 'react-router-dom';
import ProfileInfo from './grand-child/ProfileInfo';
import ChangePassword from './grand-child/change-passowrd';
import ViewAccount from './grand-child/view-account';

const ProfilePage = () => {
  // eslint-disable-next-line no-console
  return (
    <>
      <div className="default-layout">
        <div className="__content">
          <div className="sidebar-layout container">
            <div className="verical-nav">
              <ul>
                <li>
                  <NavLink activeClassName="active" to="/dashboard/acount-settings/change-password">
                    Change Password
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active" to="/dashboard/acount-settings/view-account">
                    View Account
                  </NavLink>
                </li>
                <li>
                  <NavLink activeClassName="active" to="/dashboard/acount-settings/delete-account">
                    Delete Account
                  </NavLink>
                </li>
              </ul>
            </div>
            <Route path="/dashboard/acount-settings/view-account" component={ViewAccount} />
            <Route path="/dashboard/acount-settings/change-password" component={ChangePassword} />
            <Route path="/dashboard/acount-settings/delete-account" component={ProfileInfo} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfilePage;
