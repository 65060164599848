/* eslint-disable no-unused-vars */
import React from 'react';
// eslint-disable-next-line no-unused-vars
import { Query } from 'react-apollo';
const ProfilePage = ({ error, isLoading, onSave }) => {
  // eslint-disable-next-line no-console
  return (
    <>
      <form className="dashboard-form-grp">
        <div className="dashboard-container">
          <h2 className="dashboard-container__title">Change Password</h2>
          <p style={{ textAlign: 'left' }}>Change Password</p>
          <div className="dashboard-form">
            <div className="full-width__input__container">
              <label className="input__label">Current Password</label>
              <input placeholder="" type="email" className="input__control" />
            </div>
            <div className="full-width__input__container ">
              <label className="input__label">New Password</label>
              <input placeholder="" type="email" className="input__control" />
            </div>
          </div>
          <div className="btn__container">
            <button className="dashboard-form-submit sumit-btn">Submit</button>
          </div>
        </div>
      </form>
    </>
  );
};

export default ProfilePage;
