import React from 'react';
import { withRouter } from 'react-router-dom';
import { Query } from 'react-apollo';
import { Route, Switch } from 'react-router-dom';
import ProfilePage from './child-pages/profile-page';
import AccountSettingsPage from './child-pages/account-setting';
import SubHeader from '../components/organisms/SubHeader/SubHeader';
// eslint-disable-next-line no-unused-vars
const Home = ({ isLoggedIn }) => {
  // eslint-disable-next-line no-console
  return (
    <>
      <SubHeader />
      <Switch>
        <Route isLoggedIn={isLoggedIn} path="/dashboard/profile" component={ProfilePage} />
        <Route isLoggedIn={isLoggedIn} path="/dashboard/inbox" component={ProfilePage} />
        <Route
          isLoggedIn={isLoggedIn}
          path="/dashboard/acount-settings"
          component={AccountSettingsPage}
        />
        <Route isLoggedIn={isLoggedIn} path="/dashboard/my-wishlist" component={ProfilePage} />
        <Route isLoggedIn={isLoggedIn} path="/dashboard/my-reservation" component={ProfilePage} />
      </Switch>
    </>
  );
};

const HomePage = withRouter(Home);
export default HomePage;
