import React from 'react';
import './host-bg-container.scss';
import { Link } from 'react-router-dom';
const Course = () => {
  return (
    <div className="section-become-host">
      <div className="container section-container">
        <h2 className="Section-Title">Create your Profile as Scooty and get Job newarby</h2>
        <Link className="global-btn-theme __btn" to="/dashboard/profile/become-host">
          Rent a Scooty
        </Link>
      </div>
    </div>
  );
};

export default Course;
