/* eslint-disable no-console */
import React from 'react';
import ClassNames from 'classnames';

export default function BookmarkBtn({ status }) {
  return (
    <span
      className={ClassNames(
        'image__likes-bookmark',
        {
          flagged: true,
        },
        {
          disabled: status,
        },
      )}
      tabIndex={-1}
    />
  );
}
