/* eslint-disable no-unused-vars */
import React from 'react';
import { withRouter } from 'react-router-dom';

const ProfilePage = ({ user, history }) => {
  const EditProfile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push('/dashboard/profile/update-profile');
  };
  const {
    first_name,
    last_name,
    username,
    email,
    address,
    type,
    name,
    email_verified,
    phone,
  } = user;
  return (
    <>
      <form className="dashboard-form-grp">
        <div className="dashboard-container">
          <h2 className="dashboard-container__title">Account Information</h2>
          <p style={{ textAlign: 'left' }}>View Account Information Stored in System</p>
          <div className="dashboard-form">
            <div className="input__container">
              <label className="input__label">First Name</label>
              <div>{first_name} </div>
            </div>
            <div className="input__container">
              <label className="input__label">Last Name</label>
              <div>{last_name}</div>
            </div>
            <div className="input__container">
              <label className="input__label">Email</label>
              <div>{email}</div>
            </div>
            <div className="input__container">
              <label className="input__label">User Name</label>
              <div>{username}</div>
            </div>
            <div className="input__container">
              <label className="input__label">Address</label>
              <div>{address}</div>
            </div>
            <div className="input__container">
              <label className="input__label">Type</label>
              <div>{type ? 'User' : 'Scooty'}</div>
            </div>
            <div className="input__container">
              <label className="input__label">Phone</label>
              <div>{phone}</div>
            </div>
            <div className="input__container">
              <label className="input__label">Email Verified</label>
              <div>{email_verified}</div>
            </div>
            <div className="btn__container">
              <button onClick={(e) => EditProfile(e)} className="dashboard-form-submit sumit-btn">
                Edit Profile
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default withRouter(ProfilePage);
