import { gql } from 'apollo-boost';

const loggedInUserDataQuery = gql`
  query user {
    user: user {
      id
      first_name
      last_name
      username
      email
      address
      type
      name
      uuid
      profile_picture
      phone
      email_verified
      phone_verified
      gender
    }
  }
`;

export default loggedInUserDataQuery;
