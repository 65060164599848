/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect, useContext } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import './header.scss';
import './mobilemenu.scss';
import ClassNames from 'classnames';
import logo from '../../../assets/images/logo.webp';
import DesktopHeader from '../../organisms/DesktopHeader';
import MobileHeader from '../../organisms/MobileHeader';
const HeaderComponent = ({
  isLoggedIn,
  location
}) => {
  const [headerTransparent, setHeaderTransparent] = useState(false);
  const navLinkClass = ClassNames(
    { 'header-mobile-container': true },
    { '--isTransparent': headerTransparent }
  );
  const {pathname} = location;
  const logout = () => {
    document.body.dispatchEvent(new CustomEvent('logout'));
  };
  const openSideNav = () => {
    document.body.classList.add('open');
  };
  const toggleMenu = () => {
    document.body.classList.remove('open');
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if(pathname.indexOf('dashboard') === -1) {
        let scroll = true;
        if (window.scrollY > 20) {
          scroll = false;
        }
        setHeaderTransparent(scroll);
      } else {
        setHeaderTransparent(false);
      }
    });
    return () => window.removeEventListener('scroll', ()=> {});
  },[pathname]);
  if (!isLoggedIn && pathname !== '/' && pathname !== '/home' && pathname.indexOf('dashboard') === -1 ) {
    return (
      <Fragment>
        <header className="header header-login">
          <div className="page__container">
            <div className="logo">
              <NavLink to="/">
                <img src={logo} alt="code Logo" />
              </NavLink>
            </div>
          </div>
        </header>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <DesktopHeader
        headerTransparent={headerTransparent}
        isLoggedIn={isLoggedIn}
      ></DesktopHeader>
      <MobileHeader
        isLoggedIn={isLoggedIn}
        toggleMenu={toggleMenu}
        navLinkClass={navLinkClass}
        openSideNav={openSideNav}
        headerTransparent={headerTransparent}
      ></MobileHeader>
    </Fragment>
  );
};

const Header = withRouter(HeaderComponent);
export default Header;
