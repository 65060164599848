import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

export default function ForgotPassword({ onSubmit, error, message, isLoading }) {
  // eslint-disable-next-line no-console
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({ email: false });
  function hasErrors() {
    if (email.trim().length === 0) {
      setErrors({ email: true });
      return true;
    }
    if (email.trim().length === 0) {
      setErrors({ email: true });
      return true;
    }
    return false;
  }
  function resetErrors() {
    setErrors({ email: false });
  }
  function onEmailChange(event) {
    setEmail(event.target.value);
    resetErrors();
  }

  function onFormSubmit(event) {
    event.preventDefault();
    if (hasErrors()) {
      return;
    }
    onSubmit({ email: email.trim() });
  }
  return (
    <div className="page-container">
      <form className="wrapper" noValidate onSubmit={onFormSubmit}>
        <div className="message-error" hidden={!error}>
          {error}
        </div>
        <div className="message-success" hidden={!message}>
          {message}
        </div>
        <label htmlFor="username" className="form-label">
          <input
            name="username"
            className="input loginForm__control--input"
            type="text"
            placeholder="Email"
            value={email}
            onChange={onEmailChange}
            disabled={isLoading}
          />
          <div className="errorMsg" hidden={!errors.email}>
            <span>*</span>
            Required
          </div>
        </label>
        <input
          type="submit"
          value="Forgot Password"
          className="btn btn-default button"
          disabled={isLoading}
        />
        <ul className="auth-options">
          <li className="auth-options__item">
            <NavLink to="/login" className="auth-options__item-login">
              Login
            </NavLink>
          </li>
        </ul>
      </form>
    </div>
  );
}
