import { gql } from 'apollo-boost';

const userPasswordUpdateQuery = gql`
  mutation changePassword($password: String!) {
    passwordSuccess: changePassword(type: $password) {
      message
      success
    }
  }
`;

export default userPasswordUpdateQuery;
