import React from 'react';
import './host-tiles.scss';

const Course = () => {
  return (
    <div className="container">
      <div className="flex-grid Onboarding">
        <div className="Onboarding-Container">
          <img
            src="https://static.eatwith.com/9f8263458846338c5343dc1353b45bcb.png"
            className="Onboarding-Icon"
          />
          <h3 className="Onboarding-Title">Register online </h3>
          <p className="Onboarding-Text">Register online as a Scooty and look for Jon nearby.</p>
        </div>
        <div className="Onboarding-Container">
          <img
            src="https://static.eatwith.com/253a7f83843766d37f8499d2a599f85e.png"
            className="Onboarding-Icon"
          />
          <h3 className="Onboarding-Title">search Online</h3>
          <p className="Onboarding-Text">Search Scooty Available in your area</p>
        </div>
        <div className="Onboarding-Container">
          <img
            src="https://static.eatwith.com/1a51cb1e7a7e66e23a778d70804e2329.png"
            className="Onboarding-Icon Onboarding-Icon--Money"
          />
          <h3 className="Onboarding-Title">Get Help what you need</h3>
          <p className="Onboarding-Text">
            Find any Babysitter nearby for your family needs based on reviews and ratings
          </p>
        </div>
      </div>
    </div>
  );
};

export default Course;
