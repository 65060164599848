import { useState } from 'react';

function getInfoFromLS() {
  const val = localStorage.getItem('user-data');
  try {
    const userInfo = JSON.parse(val);
    return userInfo || {};
  } catch (e) {
    // console.log('Clear localstorage here');
  }
  return {};
}

export default function useUserInfo() {
  const [get, set] = useState(getInfoFromLS());
  const setter = (info) => {
    localStorage.setItem('user-data', JSON.stringify(info));
    set(info);
  };

  return [get, setter];
}
