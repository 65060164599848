import React, { createContext, useReducer } from 'react';

function translationRedeucer(state, action) {
  switch (action.type) {
    case 'set':
      return action.data;
    default:
      return state;
  }
}
export const TranslationCtx = createContext({});

export default function TransalationProvider({ children }) {
  let translations = localStorage.getItem('translations') || '{}';
  try {
    translations = JSON.parse(translations);
  } catch (e) {
    translations = {};
  }
  const [state, dispatch] = useReducer(translationRedeucer, translations);
  const t = (phrase) => state[phrase] || phrase;
  return <TranslationCtx.Provider value={{ t, dispatch }}>{children}</TranslationCtx.Provider>;
}
