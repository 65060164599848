// @ts-nocheck
/* eslint-disable no-console */
import React from 'react';
import { Query } from 'react-apollo';
import LOGGEDIN_USER_DATA from '../../../graphql/query/loggedinUserDataQuery';

import ViewAccountComponent from '../../../components/profile/view-account';
// eslint-disable-next-line no-unused-vars
function ProfileInfo({ user }) {
  return <ViewAccountComponent user={user} />;
}

export default function ProfileInfoPage({ isLoggedIn }) {
  return (
    <Query query={LOGGEDIN_USER_DATA} fetchPolicy="network-only">
      {({ data, isLoading, error }) => {
        if (isLoading) return null;
        if (!data || !data.user) return null;
        return (
          <ProfileInfo
            isLoggedIn={isLoggedIn}
            user={data && data.user ? data.user : {}}
            error={error}
          />
        );
      }}
    </Query>
  );
}
