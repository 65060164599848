import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

export default function RegisterComponent({ onSubmit, error, isLoading }) {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  // const [rememberMe, setRememberMe] = useState(false);
  const [errors, setErrors] = useState({
    username: false,
    password: false,
    email: false,
  });

  function hasErrors() {
    if (email.trim().length === 0 && username.trim().length === 0 && password.trim().length === 0) {
      setErrors({ email: true, password: true, username: true });
      return true;
    }
    if (email.trim().length === 0) {
      setErrors({ email: true });
      return true;
    }
    if (password.trim().length === 0) {
      setErrors({ password: true });
      return true;
    }
    if (username.trim().length === 0) {
      setErrors({ username: true });
      return true;
    }
    return false;
  }

  function resetErrors() {
    setErrors({ email: false, password: false, username: false });
  }

  function onEmailChange(event) {
    setEmail(event.target.value);
    resetErrors();
  }

  function onPasswordChange(event) {
    setPassword(event.target.value);
    resetErrors();
  }

  function onUserNameChange(event) {
    setUsername(event.target.value);
    resetErrors();
  }

  function onFormSubmit(event) {
    event.preventDefault();
    if (hasErrors()) {
      return;
    }
    onSubmit({
      email: email.trim(),
      password: password.trim(),
      username: username.trim(),
    });
  }

  return (
    <div className="page-container">
      <form className="wrapper" noValidate onSubmit={onFormSubmit}>
        <div className="message-error" hidden={!error}>
          {error}
        </div>
        <label htmlFor="username" className="form-label">
          <input
            name="username"
            className="input"
            type="text"
            placeholder="Username"
            value={username}
            onChange={onUserNameChange}
            disabled={isLoading}
          />
          <div className="errorMsg" hidden={!errors.username}>
            <span>*</span>
            Required
          </div>
        </label>
        <label htmlFor="email" className="form-label">
          <input
            name="email"
            className="input"
            type="email"
            placeholder="Email"
            value={email}
            onChange={onEmailChange}
            disabled={isLoading}
          />
          <div className="errorMsg" hidden={!errors.email}>
            <span>*</span>
            Required
          </div>
        </label>
        <label htmlFor="password" className="form-label">
          <input
            name="password"
            className="input"
            type="password"
            placeholder="Password"
            value={password}
            onChange={onPasswordChange}
            disabled={isLoading}
          />
          <div className="errorMsg" hidden={!errors.password}>
            <span>*</span>
            Required
          </div>
        </label>
        <input type="submit" value="User Signup" className="button" disabled={isLoading} />
        <ul className="auth-options">
          <li className="auth-options__item">
            <NavLink to="/login" className="auth-options__item-forgetPass">
              User Login
            </NavLink>
          </li>
        </ul>
      </form>
    </div>
  );
}
