import React, { useState, Fragment } from 'react';
import './notifications.scss';
import ClassNames from 'classnames';
import NotificationIconBg from '../../../assets/images/alerts-bg.svg';
import NotificationIcon from '../../../assets/images/alerts.svg';

import { NavLink } from 'react-router-dom';

// eslint-disable-next-line jsx-a11y/click-events-have-key-events

const NotificationBar = ({ headerTransparent }) => {
  const [data] = useState([]);

  return (
    <Fragment>
      <NavLink to="/" className="__navItem">
        <img
          className="__navItem__img"
          src={headerTransparent ? NotificationIcon : NotificationIconBg}
          alt="profile-icon"
        />
      </NavLink>

      <div
        className={ClassNames('side-notification', {
          notify: false,
        })}
      >
        <h5 className="side-notification_title">{'Notifications'}</h5>
        <ul className="side-notification_list">
          {data &&
            data.slice(0, 4).map((i, index) => {
              return (
                <li key={index} className="side-notification_item">
                  <span role="button" tabIndex={-1}>
                    {/* {i.message && i.message.substring(0, 30).concat('...')} */}
                    {i.message}
                  </span>
                </li>
              );
            })}
        </ul>
      </div>
    </Fragment>
  );
};
export default NotificationBar;
