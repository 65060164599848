import React from 'react';
import './scootytiles.scss';
import Palatte from '../molecules/Palette/index';
const Data = [1, 2, 3, 4, 5, 6, 7, 8];
const Course = () => {
  const seeMore = () => {};
  return (
    <div className="section-block">
      <div className="section-inner-container">
        <div className="section-title">Explore Scooty Around you</div>
        <div className="nanny-container">
          <div className="grid-row">
            {Data.map((i, index) => {
              return (
                <div className="grid-item" key={index}>
                  <a className="wrapping-link" href="https://thecodeteam.com/projects/rex-ray/"></a>
                  <div className="grid-item-wrapper"></div>
                  <div className="grid-item-tag">
                    <span>$45/Month</span>
                  </div>
                  <div className="grid-item-star">
                    <span>
                      Best
                      <svg
                        width="12px"
                        height="12px"
                        viewBox="0 0 14 14"
                        className="Badge__InlineIcon-rsb6w9-0 esoxAr"
                      >
                        <path
                          d="M10.725 10.75h-8.45a.32.32 0 00-.325.313v.624a.32.32 0 00.325.313h8.45a.32.32 0 00.325-.313v-.624a.32.32 0 00-.325-.313zm1.3-6.25c-.538 0-.975.42-.975.938a.89.89 0 00.09.386l-1.471.848a.663.663 0 01-.898-.227L7.115 3.66a.92.92 0 00.36-.723C7.475 2.42 7.038 2 6.5 2s-.975.42-.975.938c0 .292.142.55.36.722L4.229 6.445a.664.664 0 01-.898.227l-1.468-.848a.912.912 0 00.089-.386c0-.518-.437-.938-.975-.938S0 4.92 0 5.438c0 .517.437.937.975.937.053 0 .106-.008.156-.016L2.6 10.125h7.8l1.469-3.766c.05.008.103.016.156.016.538 0 .975-.42.975-.938 0-.517-.437-.937-.975-.937z"
                          fill="#8a6eff"
                          fillRule="nonzero"
                        ></path>
                      </svg>
                    </span>
                  </div>
                  <div className="grid-item-body">
                    <div className="grid-item-body__container">
                      <p>
                        <span color="orange" className="card-orange">
                          Donna
                        </span>
                        Jackline
                      </p>
                      <p className="card-title">Rental</p>
                      <div className="card-rating">
                        <span className="card-ratings"> Ratings - 4.9/5</span>
                        <svg
                          width="12px"
                          height="12px"
                          viewBox="0 0 12 11"
                          className="ReviewRating__StyledIcon-sc-1453fpi-0 gQsNur"
                        >
                          <path
                            d="M6 9.06l-3.527 1.794.617-3.908-2.796-2.8L4.2 3.524 6 0l1.799 3.524 3.907.622-2.796 2.8.617 3.908z"
                            fill="#fd7854"
                            fillRule="nonzero"
                          ></path>
                        </svg>{' '}
                        <br />
                        <span className="card-ratings"> Available</span>
                      </div>
                    </div>
                  </div>
                  <div className="grid-item-footer">
                    <Palatte />
                  </div>
                </div>
              );
            })}
          </div>
          <button className="__btn" onClik={seeMore}>
            See More
          </button>
        </div>
      </div>
    </div>
  );
};

export default Course;
