import React from 'react';
import '../components/home/home.scss';
import { withRouter, Link } from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import { Query } from 'react-apollo';
import HostTiles from '../components/scooty-tiles/host-tiles';
import HostContainer from '../components/scooty-tiles/host-bg-container';
import HostAddon from '../components/scooty-tiles/host-addon';
// eslint-disable-next-line no-unused-vars
const Home = ({ isLoggedIn }) => {
  const arr = ['primary', 'secondary', 'ternary'];

  // eslint-disable-next-line no-console
  const index = arr[Math.floor(Math.random() * arr.length)];
  const cssClass = `bg-container  ${index}`;
  return (
    <>
      <div className="heroSection-container homePageSection ">
        <div className={cssClass}></div>
        <div className="heroSection-video-container">
          <div className="bg-video" style={{ width: '100%', height: '790px' }}></div>
        </div>
        <div dir="auto" className="heroSection__content">
          <h1 className="heading HeroSection__TitleHeading" color="#fffad1" type="hero">
            Find Scooty for your needs or Rent a Scooty
          </h1>
          <Link className="global-btn-theme" to="/dashboard/profile/become-host">
            Rent a Scooty
          </Link>
          <p type="attention" className="Text__StyledText-sc-1y7eo9o-0 eMaLRG"></p>
        </div>
      </div>
      <HostTiles />
      <HostContainer />
      <HostAddon />
    </>
  );
};

const HomePage = withRouter(Home);
export default HomePage;
