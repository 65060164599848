/* eslint-disable no-unused-vars */
import React from 'react';
// eslint-disable-next-line no-unused-vars
import { Query } from 'react-apollo';
const ProfilePage = ({ error, isLoading, onSave, user }) => {
  // eslint-disable-next-line no-console
  const { first_name, last_name, username, email, address, type, name } = user;
  return (
    <>
      <form className="dashboard-form-grp">
        <div className="dashboard-container">
          <h2 className="dashboard-container__title">Personal information</h2>
          <p style={{ textAlign: 'left' }}>
            Eatwith is all about people! Help future guests get to know you. Tell them about the
            things you like: your food preferences, favorite travel destination, etc..
          </p>
          <div className="dashboard-form">
            <div className="input__container">
              <label className="input__label">Email</label>
              <input placeholder="" type="email" className="input__control" />
            </div>
            <div className="input__container">
              <label className="input__label">Email</label>
              <input placeholder="" type="email" className="input__control" />
            </div>
            <div className="input__container">
              <label className="input__label">Email</label>
              <input placeholder="" type="email" className="input__control" />
            </div>
            <div className="input__container">
              <label className="input__label">Email</label>
              <input placeholder="" type="email" className="input__control" />
            </div>
          </div>
        </div>
        <div className="dashboard-container">
          <h2 className="dashboard-container__title">Address information</h2>
          <p style={{ textAlign: 'left' }}>
            Be sure to fill in your complete address so that your experiences show up in search
            results. It will not be displayed and will only be shared with your future guests.
          </p>
          <div className="dashboard-form">
            <div className="full-width__input__container">
              <label className="input__label">Full Address</label>
              <input placeholder="" type="email" className="input__control" />
            </div>
            <div className="input__container">
              <label className="input__label">Email</label>
              <input placeholder="" type="email" className="input__control" />
            </div>
            <div className="input__container">
              <label className="input__label">Email</label>
              <input placeholder="" type="email" className="input__control" />
            </div>
            <div className="input__container">
              <label className="input__label">Email</label>
              <input placeholder="" type="email" className="input__control" />
            </div>
          </div>
        </div>
        <div className="btn__container">
          <button className="dashboard-form-submit dashboard-full-btn sumit-btn">Submit</button>
        </div>
      </form>
    </>
  );
};

export default ProfilePage;
