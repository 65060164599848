import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

export default function LoginComponent({ onSubmit, isLoading, error }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({ username: false, password: false });

  function hasErrors() {
    if (email.trim().length === 0 && password.trim().length === 0) {
      setErrors({ email: true, password: true });
      return true;
    }
    if (email.trim().length === 0) {
      setErrors({ email: true });
      return true;
    }
    if (password.trim().length === 0) {
      setErrors({ password: true });
      return true;
    }
    return false;
  }

  function resetErrors() {
    setErrors({ username: false, password: false });
  }

  function onEmailChange(event) {
    setEmail(event.target.value);
    resetErrors();
  }

  function onPasswordChange(event) {
    setPassword(event.target.value);
    resetErrors();
  }

  function onFormSubmit(event) {
    event.preventDefault();
    if (hasErrors()) {
      return;
    }
    onSubmit({ email: email.trim(), password: password.trim() });
  }

  return (
    <div className="page-container">
      <form className="wrapper" noValidate onSubmit={onFormSubmit}>
        <div className="message-error" hidden={!error}>
          {error}
        </div>
        <label htmlFor="username" className="form-label">
          <input
            name="username"
            className="input"
            type="text"
            placeholder="Email"
            value={email}
            onChange={onEmailChange}
            disabled={isLoading}
          />
          <div className="errorMsg" hidden={!errors.email}>
            <span>*</span>
            Required
          </div>
        </label>
        <label htmlFor="password" className="form-label">
          <input
            name="password"
            className="input"
            type="password"
            placeholder="Password"
            value={password}
            onChange={onPasswordChange}
            disabled={isLoading}
          />
          <div className="errorMsg" hidden={!errors.password}>
            <span>*</span>
            Required
          </div>
        </label>
        <input
          type="submit"
          value="Sign in"
          className="btn btn-default button"
          disabled={isLoading}
        />
        <ul className="auth-options">
          <li className="auth-options__item">
            <NavLink to="/forgetpassword" className="auth-options__item-forgetPass">
              Forgot Password?
            </NavLink>
          </li>
          <li className="auth-options__item">
            <NavLink to="/register" className="auth-options__item-register">
              User Signup
            </NavLink>
          </li>
        </ul>
      </form>
    </div>
  );
}
